<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            lg="2"
          >
            <!-- Create Customer -->
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="primary"
              class="me-3"
              @click="isSaveDialogOpen = true"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>Add Customer</span>
            </v-btn>
          </v-col>

          <!-- Save Dialog -->
          <v-dialog
            v-model="isSaveDialogOpen"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="d-flex align-center mv-4 mb-4">
                {{ customer.id ? 'Edit' : 'New' }} Customer
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isSaveDialogOpen = false; saveForm.reset(); customer = {}"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="saveForm"
                  v-model="saveValid"
                >
                  <v-text-field
                    v-model="customer.name"
                    outlined
                    dense
                    label="Name"
                    :rules="[validators.required]"
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="customer.company"
                        outlined
                        dense
                        label="Company"
                        :rules="[validators.required]"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="customer.gender"
                        :items="['Male', 'Female']"
                        outlined
                        dense
                        label="Gender"
                        :rules="[validators.required]"
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="customer.email"
                        outlined
                        dense
                        label="Email"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="customer.contact_number"
                        outlined
                        dense
                        label="Number"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-textarea
                    v-model="customer.note"
                    outlined
                    rows="3"
                    label="Notes"
                  ></v-textarea>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  :loading="saveCustomerLoading"
                  :disabled="saveCustomerLoading || !saveValid"
                  @click="saveCustomer"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="4"
            lg="3"
            class="d-flex pl-sm-0"
          >
            <!-- Search Customer -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Customer name
                </li>
                <li>
                  Customer company
                </li>
                <li>
                  Customer number
                </li>
                <li>
                  Customer email
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Customers"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchCustomers"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Customer List -->
      <v-data-table
        :headers="tableHeaders"
        :items="customers"
        :options.sync="options"
        :items-per-page="100"
        :loading="listLoading"
        mobile-breakpoint="0"
        hide-default-footer
        fixed-header
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Name -->
        <template #[`item.name`]="{item}">
          <div
            class="text-truncate"
            style="width: 200px;"
          >
            {{ item.name }}
          </div>
        </template>

        <!-- Company -->
        <template #[`item.company`]="{item}">
          <div
            class="text-truncate"
            style="width: 250px;"
          >
            {{ item.company }}
          </div>
        </template>

        <!-- Notes -->
        <template #[`item.note`]="{item}">
          <div
            class="text-truncate"
            style="width: 500px;"
          >
            {{ item.note }}
          </div>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'customer-statement', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiTextBox }}
                  </v-icon>
                  <span>View Statement</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="fetchCustomer(item.id); isSaveDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="customer = item; isDeleteDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmHeaders"
        :items="customers"
        :options.sync="options"
        :items-per-page="100"
        :loading="listLoading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        fixed-header
        class="text-no-wrap fixed-action d-block d-sm-none"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <div class="d-flex">
              <p class="mt-3">
                Company: {{ item.company }}
              </p>
              <v-spacer></v-spacer>
              <div class="fixed-expand-action">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item :to="{ name: 'customer-statement', params: { id: item.id } }">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiTextBox }}
                        </v-icon>
                        <span class="caption">View Statement</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="fetchCustomer(item.id); isSaveDialogOpen = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span class="caption">Edit</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="customer = item; isDeleteDialogOpen = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span class="caption">Delete</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <p>Gender: {{ item.gender }}</p>
            <p>Contact No: {{ item.contact_number }}</p>
            <p>Email: {{ item.email }}</p>
            <p
              class="text-wrap"
              v-html="item.note"
            ></p>
          </td>
        </template>
      </v-data-table>

      <!-- Delete Dialog -->
      <v-dialog
        v-model="isDeleteDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Delete {{ customer.name }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDeleteDialogOpen = false; customer = {}"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This customer will be removed from this list. It will no longer appear in searches or available for use.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deleteCustomerLoading"
              :disabled="deleteCustomerLoading"
              class="mt-3"
              @click="deleteCustomer(customer.id)"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchCustomers"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiTextBox,
  mdiClose,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const customers = ref([])
    const customer = ref({})
    const searchText = ref('')

    const saveForm = ref(null)
    const saveValid = ref(false)
    const isSaveDialogOpen = ref(false)
    const saveCustomerLoading = ref(false)

    const isDeleteDialogOpen = ref(false)
    const deleteCustomerLoading = ref(false)

    // Table Handlers
    const listLoading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['name'],
      sortDesc: [false],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        { text: 'COMPANY', value: 'company' },
        { text: 'GENDER', value: 'gender', sortable: false },
        { text: 'NUMBER', value: 'contact_number', sortable: false },
        { text: 'EMAIL', value: 'email', sortable: false },
        { text: 'JOINED AT', value: 'joined_at' },
        { text: 'NOTE', value: 'note', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'right',
          sortable: false,
          class: 'bg-white',
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        { text: 'JOINED AT', value: 'joined_at' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Methods
    const fetchCustomers = () => {
      listLoading.value = true
      store
        .dispatch('clericalSettingsStore/fetchCustomers', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          customers.value = data
          tablePagination.value = pagination
          listLoading.value = false
        })
        .catch(error => {
          listLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customers. Please refresh!')
        })
    }
    const fetchCustomer = id => {
      store
        .dispatch('clericalSettingsStore/fetchCustomer', { id })
        .then(response => {
          customer.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customer. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchCustomers()
    }
    const saveCustomer = () => {
      saveCustomerLoading.value = true
      store
        .dispatch(`clericalSettingsStore/${customer.value.id ? 'updateCustomer' : 'createCustomer'}`, customer.value)
        .then(response => {
          snackbarService.success(response.data.message)
          fetchCustomers()
          isSaveDialogOpen.value = false
          saveCustomerLoading.value = false
          saveForm.value.reset()
          customer.value = {}
        })
        .catch(error => {
          saveCustomerLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding customer. Please refresh!')
        })
    }
    const deleteCustomer = customerId => {
      deleteCustomerLoading.value = true
      store
        .dispatch('clericalSettingsStore/deleteCustomer', { id: customerId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchCustomers()
          isDeleteDialogOpen.value = false
          deleteCustomerLoading.value = false
          customer.value = {}
        })
        .catch(error => {
          deleteCustomerLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting customer. Please refresh!')
        })
    }

    // Watch
    let timer = null
    watch([searchText, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchCustomers()
      }, 200)
    })

    return {
      // Properties
      customers,
      customer,
      searchText,

      saveForm,
      saveValid,
      isSaveDialogOpen,
      saveCustomerLoading,

      isDeleteDialogOpen,
      deleteCustomerLoading,

      // Table Handlers
      listLoading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiTextBox,
        mdiClose,
      },

      // Methods
      fetchCustomers,
      fetchCustomer,
      listLengthChange,
      saveCustomer,
      deleteCustomer,
    }
  },
}
</script>
